import { merge } from "lodash";
import { deCommon } from "../../../common";
import { BaseLang } from "../BaseLang";

export const de: BaseLang = merge(deCommon, {
    apps: {
        account: {
            name: "Konto",
            description: "Verwalten Sie Ihr Konto und Ihre Organisation."
        },
        timestamping: {
            name: "Timestamping",
            description: "Beweisen Sie das genaue Datum und die Datenintegrität Ihrer wichtigen Dokumente." // to review
        },
        sealing: {
            name: "Sealing",
            description: "Versiegeln Sie Ihre Dokumente und Daten, um deren Integrität und Authentizität zu gewährleisten."
        },
        archiving: {
            name: "Archiving",
            description: "Archivieren Sie Ihre Daten und Dokumente sicher und dauerhaft."
        }
    },
    register: {
        slogan: "Trust reinvented"
    },
    onboarding: {
        title: "Willkommen bei Evidency!",
        subtitle: "Spezialist für das Management des Lebenszyklus digitaler Beweise."
    }
});
